@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.flex-row {
    display: flex;
    flex-grow: 1;
}

.flex-grow {
    flex-grow: 1;
}